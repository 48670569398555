import axios from 'axios';
import * as React from 'react';

import {LetterList, NavMenu} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';

import 'less/shop.less';
import {BrComponent} from '@bloomreach/react-sdk';

export default class Shop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
        };
    }

    componentDidMount() {
        axios.get('CatalogService/api/v1/categories').then(response => {
            let categories = response.data.map(a => {
                return {
                    name: a.name,
                    slugName: a.slugName,
                }
            });

            this.setState({
                categories: categories,
            });
        });
    }

    render() {
        const {categories} = this.state;

        return (<div className="shop-page">
            <PageMetaData
                title="Shop"
                pageType="other"
                trackAnalytics={false} />
            <NavMenu
                isPDP={true}
                className="header-nav-menu"
                options={[{label: 'Home', url: ''},
                    {label: 'All Categories', url: ''}]} />
            <div className="shop-page_header">
                <h1>Categories</h1>
            </div>
            <div className="shop-page_top">
                <BrComponent path={'main/container'} />
            </div>
            <LetterList items={categories} urlPath={'shop'} title={'Categories'} />
        </div>);
    }
}
